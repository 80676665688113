/* eslint-disable react/prop-types */
import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { uniqBy } from 'lodash';
import { withHomePageSliderSection } from 'components/withHomePageSliderSection';
import { colorsV2 } from 'style/colors-v2';
import { HeadlineIcon } from 'components/HeadlineIcon';
import { fromScreen } from 'utils/media-query/responsive.util';
import styled, { keyframes } from 'styled-components';
import cl from 'classnames';
import { LazyImage } from 'components/LazyImage';
import { Typography } from 'components/DesignSystemV2/Typography';
import {
  IconsInfoLinearthinkingAmbassador,
  IconsSchoolLearningCertificateMedal,
  IconsSchoolLearningGraduateHat,
  IconsVideoMovies
} from 'components/Icon/SVGIcons';
import ButtonText from 'components/ButtonText';
import Marquee from 'react-fast-marquee';

const Main = styled.div`
  .section-title {
    padding: 0 16px;
    margin: 0 auto 24px;
    > div {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
    }
  }
  .headline-icon {
    display: block;
  }
  ${fromScreen(458)} {
    .section-title {
      max-width: 457px;
    }
  }

  ${fromScreen(776)} {
    .section-title {
      max-width: 712px;
      padding: 0;
      margin: 0 auto 40px;
    }
  }
  ${fromScreen(1144)} {
    .section-title {
      max-width: 100%;
    }
  }
`;

const SlideContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  .keen-slider {
    height: 480px;
  }

  ${fromScreen(776)} {
    gap: 24px;
  }
`;

const TeacherImageSection = styled.div`
  height: 380px;
  position: relative;
  overflow: hidden;
  img {
    height: 100%;
    border-radius: 16px;
  }
`;
const TeacherNameSection = styled.div`
  .teacher-subtitle {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const Text = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  display: none;
  align-items: end;
  background: linear-gradient(
    180deg,
    rgba(33, 38, 45, 0) 19.31%,
    rgba(33, 38, 45, 0.8) 83.86%,
    #21262d 100%
  );
  padding: 20px;
  bottom: 0;
  border-radius: 16px;
`;

const DetailInfoTag = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  display: grid;
  align-items: end;
  padding: 20px;
  bottom: 0;
  border-radius: 16px;
`;

const InfoTagContent = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 6px 12px 2px;
  background: ${colorsV2.neutral160};
  border-radius: 12px;
`;

const Ambassador = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  gap: 8px;
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content auto;
  align-items: center;
  gap: 8px;
  .info-title {
    max-width: 230px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .info-subtitle {
    max-width: 230px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const MaskLeft = styled.div`
  position: absolute;
  display: none;
  height: 100%;
  width: 160px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  z-index: 1;
  pointer-events: none;
  ${fromScreen(776)} {
    display: block;
  }
`;

const MaskRight = styled.div`
  position: absolute;
  display: none;
  height: 100%;
  width: 160px;
  right: -2px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  transform: rotate(180deg);
  z-index: 1;
  pointer-events: none;
  ${fromScreen(776)} {
    display: block;
  }
`;

const FadeIn = keyframes`
  0% {
    bottom: -35%;
  }
  100% {
    bottom: 0%;
  }
`;

const TeacherSlideItem = styled.div`
  margin: 0 8px;
  width: 324px;
  display: grid;
  grid-template-columns: 1fr;
  .teacher-info {
    display: none;
  }
  .teacher-image {
    height: 100%;
    img {
      object-fit: cover;
    }
  }
  &:hover {
    ${Text} {
      bottom: 0;
      display: grid;
      animation: ${FadeIn} 0.5s;
    }
    ${DetailInfoTag} {
      display: none;
    }
    .teacher-title {
      margin-bottom: 0;
    }
    .teacher-info {
      display: block;
    }
  }
  ${fromScreen(458)} {
    margin: 0 12px;
  }
`;

const TextLinkWrap = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  margin-top: 70px;
  ${fromScreen(776)} {
    display: none;
  }
`;

export const getUniqueTeachers = data => uniqBy(data, 'name');

export const useAllTeacherData = () => {
  const data = useStaticQuery(graphql`
    {
      allGoogleSpreadsheetDolLandingPageMarLevelData {
        nodes {
          levelVideo
          levelVideoThumbnailHandle
          availableSeat
          classCode
          classDate
          classGroup
          classListing
          classTime
          inputLevel
          level
          slug
          levelDescription
          levelDisplay
          outputLevel
          startDate
          teacherRw
          teacherSl
          levelSubtext
          levelInputOutputMobile
          levelInputOutputDesktop
          levelBestseller
          levelUpdated
          numOfStudent
          whatYouGet1
          whatYouGet2
          whatYouGet3
          whatYouGet4
          whatYouGet5
          whatYouGet6
          whatYouGet7
          whatYouGet8
        }
      }
      allGoogleSpreadsheetDolLandingPageTeacherInfo {
        nodes {
          id
          name
          url
          displayName
          fullName
          feedbackScore
          listingImageHandler
          feedbackReceive
          highlightTitle4
          highlightTitle3
          highlightTitle2
          highlightTitle1
          highlightSubtext4
          highlightSubtext3
          highlightSubtext2
          highlightSubtext1
          highlightIcon4Handler
          highlightIcon3Handler
          highlightIcon2Handler
          highlightIcon1Handler
          displayNameSubtitle
          detailImageHandler
          teacherDescription
          numberOfStudent
          videoIntroduction
        }
      }
    }
  `);
  const teachers = data.allGoogleSpreadsheetDolLandingPageTeacherInfo.nodes
    .map(item => {
      const {
        id,
        highlightTitle4,
        highlightTitle3,
        highlightTitle2,
        highlightTitle1,
        highlightSubtext4,
        highlightSubtext3,
        highlightSubtext2,
        highlightSubtext1
      } = item;
      item.highlights = [
        {
          id: `${id}-1`,
          title: highlightTitle1,
          subText: highlightSubtext1,
          icon: (
            <IconsSchoolLearningCertificateMedal
              width={20}
              height={20}
              color={colorsV2.white80}
            />
          )
        },
        {
          id: `${id}-2`,
          title: highlightTitle2,
          subText: highlightSubtext2,
          icon: (
            <IconsSchoolLearningGraduateHat
              width={20}
              height={20}
              color={colorsV2.white80}
            />
          )
        },
        {
          id: `${id}-3`,
          title: highlightTitle3,
          subText: highlightSubtext3,
          icon: (
            <IconsSchoolLearningGraduateHat
              width={20}
              height={20}
              color={colorsV2.white80}
            />
          )
        },
        {
          id: `${id}-4`,
          title: highlightTitle4,
          subText: highlightSubtext4,
          icon: (
            <IconsVideoMovies width={20} height={20} color={colorsV2.white80} />
          )
        }
      ].filter(item => Boolean(item.title));
      return item;
    })
    .filter(item => Boolean(item?.url));

  const allLevels = data.allGoogleSpreadsheetDolLandingPageMarLevelData.nodes;

  const getTeacherOfLevel = levelName => {
    const levelFound = allLevels.find(item => item.level === levelName);
    const levelTeachers = teachers.filter(
      teacher =>
        teacher.name?.includes(levelFound.teacherRw) ||
        teacher.name?.includes(levelFound.teacherSl)
    );
    return levelTeachers;
  };

  return { getTeacherOfLevel, teachers, allLevels };
};

const TeacherInfoContent = ({ icon = '', title = '', subTitle = '' }) => {
  return (
    <InfoWrapper>
      {icon}
      <Typography
        className="info-title"
        variant="semi-bold/16-24"
        color={colorsV2.white100}
        v3
      >
        {title}
      </Typography>
      <Typography
        className="info-subtitle"
        variant="regular/16-24"
        color={colorsV2.white80}
      >
        {subTitle}
      </Typography>
    </InfoWrapper>
  );
};
const SlideItems = ({ teachers }) => {
  return (
    <Marquee speed={100} pauseOnHover={true}>
      <div className="keen-slider">
        {teachers?.slice(0, 11).map((eachTeacher, idx) => {
          return (
            <TeacherSlideItem
              key={idx}
              className={cl('keen-slider__slide', 'teacher-image-wrap')}
            >
              <TeacherImageSection>
                <LazyImage
                  className="teacher-image"
                  handle={eachTeacher?.listingImageHandler}
                  alt={`${eachTeacher?.name} avatar`}
                />
                <Text>
                  <div>
                    {eachTeacher?.highlights?.map((eachHighLight, hIdx) => (
                      <TeacherInfoContent
                        key={hIdx}
                        icon={eachHighLight?.icon}
                        title={eachHighLight?.title}
                        subTitle={eachHighLight?.subText}
                      />
                    ))}
                  </div>
                </Text>
                <DetailInfoTag>
                  <InfoTagContent>
                    <TeacherInfoContent
                      icon={eachTeacher?.highlights[0]?.icon}
                      title={eachTeacher?.highlights[0]?.title}
                      subTitle={eachTeacher?.highlights[0]?.subText}
                    />
                  </InfoTagContent>
                </DetailInfoTag>
              </TeacherImageSection>
              <TeacherNameSection>
                <Ambassador>
                  <Typography
                    variant="semi-bold/24-32"
                    color={colorsV2.neutralPrimary}
                    v3
                  >
                    {eachTeacher?.fullName}
                  </Typography>
                  <IconsInfoLinearthinkingAmbassador
                    width={24}
                    height={24}
                    color={colorsV2.primary100}
                  />
                </Ambassador>

                <Typography
                  className="teacher-subtitle"
                  variant="regular/16-24"
                  color={colorsV2.gray120}
                  v3
                >
                  {eachTeacher?.displayNameSubtitle}
                </Typography>
              </TeacherNameSection>
            </TeacherSlideItem>
          );
        })}
      </div>
    </Marquee>
  );
};

const AllDolTeachersV3 = () => {
  const { teachers } = useAllTeacherData();

  return (
    <Main>
      <HeadlineIcon
        iconHandle="0GYERmATmSRJWjiRPE4w"
        url="/linearteachers"
        className="section-title"
        isHomePage
      >
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Đội ngũ giáo viên
        </Typography>
      </HeadlineIcon>
      <SlideContainer>
        <SlideItems teachers={teachers} />
        <MaskLeft />
        <MaskRight />
      </SlideContainer>
      <TextLinkWrap>
        <Link to="/linearteachers">
          <ButtonText content="Xem tất cả" type="primary" />
        </Link>
      </TextLinkWrap>
    </Main>
  );
};

export default withHomePageSliderSection(
  AllDolTeachersV3,
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);

import React from 'react';
import styled, { css } from 'styled-components';

import { fromScreen } from 'utils/media-query/responsive.util';

export const withHomePageSliderSection =
  (Component, sectionProps = {}, containerProps = {}) =>
  props => {
    return (
      <Section {...sectionProps}>
        <Container {...containerProps}>
          <Component {...props} />
        </Container>
      </Section>
    );
  };

export const Section = styled.section.attrs({ className: 'section' })`
  position: relative;
  ${({ selfish }) =>
    selfish &&
    css`
      padding-top: 26px;
      padding-bottom: 26px;
    `}

  ${fromScreen(776)} {
    ${({ selfish }) =>
      selfish &&
      css`
        padding-top: 32px;
        padding-bottom: 32px;
      `}
  }
`;

const Container = styled.article`
  margin: 0 auto;
  max-width: 100%;

  ${fromScreen(1144)} {
    max-width: 1080px;
  }

  img {
    max-width: 100%;
  }

  video {
    max-width: 100%;
    outline: none;
  }
`;
